import logo from './logo.svg';
import '../src/style.css';

function App() {
  return (
    <>
      <header>
        <h1>Politique de Confidentialité</h1>
      </header>
      <main>
        <section>
          <h2>Introduction</h2>
          <p>Cette politique de confidentialité définit la manière dont Yatouu utilise et protège les informations que vous nous fournissez lorsque vous utilisez ce site web. Yatouu est engagée pour assurer que votre vie privée est protégée. Si nous vous demandons de fournir certaines informations par lesquelles vous pouvez être identifié lors de l'utilisation de ce site web, alors vous pouvez être assuré qu'elles ne seront utilisées que conformément à cette déclaration de confidentialité.</p>
        </section>

        <section>
          <h2>Collecte des informations</h2>
          <p>Nous recueillons des informations lorsque vous vous inscrivez sur notre site, passez une commande, vous abonnez à notre newsletter, répondez à un sondage ou remplissez un formulaire. Les informations recueillies incluent votre nom, votre adresse e-mail, numéro de téléphone, votre addresse...</p>
        </section>

        <section>
          <h2>Utilisation des informations</h2>
          <p>Les informations que nous recueillons auprès de vous peuvent être utilisées pour :</p>
          <ul>
            <li>Personnaliser votre expérience et répondre à vos besoins individuels</li>
            <li>Fournir un contenu publicitaire personnalisé</li>
            <li>Améliorer notre site Web</li>
            <li>Améliorer le service client et vos besoins de prise en charge</li>
          </ul>
        </section>

        <section>
          <h2>Confidentialité du commerce en ligne</h2>
          <p>Nous sommes les seuls propriétaires des informations recueillies sur ce site. Vos informations personnelles ne seront pas vendues, échangées, transférées ou données à une autre société pour n’importe quelle raison, sans votre consentement, en dehors de ce qui est nécessaire pour répondre à une demande et / ou une transaction, comme par exemple pour expédier une commande.</p>
        </section>

        <section>
          <h2>Divulgation à des tiers</h2>
          <p>Nous ne vendons, n'échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela n'inclut pas les tierces parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.</p>
        </section>

        <section>
          <h2>Sécurité</h2>
          <p>Nous nous engageons à assurer la sécurité de vos informations. Afin de prévenir l'accès non autorisé ou la divulgation, nous avons mis en place des procédures physiques, électroniques et de gestion appropriées pour sauvegarder et sécuriser les informations que nous recueillons en ligne.</p>
        </section>

        <section>
          <h2>Liens vers d'autres sites</h2>
          <p>Notre site web peut contenir des liens vers d'autres sites d'intérêt. Cependant, une fois que vous avez utilisé ces liens pour quitter notre site, vous devez noter que nous n'avons aucun contrôle sur cet autre site. Par conséquent, nous ne pouvons pas être responsables de la protection et de la confidentialité des informations que vous fournissez en visitant ces sites et ces sites ne sont pas régis par la présente déclaration de confidentialité. Vous devez faire preuve de prudence et consulter la déclaration de confidentialité applicable au site en question.</p>
        </section>

        <section>
          <h2>Contrôle de vos informations personnelles</h2>
          <p>Vous pouvez choisir de restreindre la collecte ou l'utilisation de vos informations personnelles de la manière suivante :</p>
          <ul>
            <li>Chaque fois que vous êtes invité à remplir un formulaire sur le site web, recherchez la case que vous pouvez cliquer pour indiquer que vous ne voulez pas que l'information soit utilisée par quiconque à des fins de marketing direct</li>
            <li>Si vous avez précédemment accepté que nous utilisions vos informations personnelles à des fins de marketing direct, vous pouvez changer d'avis à tout moment en nous écrivant à contact@yatouu.ci</li>
          </ul>
        </section>

        <section>
          <h2>Modification de notre politique de confidentialité</h2>
          <p>Nous pouvons mettre à jour cette politique de confidentialité de temps à autre en publiant une nouvelle version sur notre site web. Vous devriez consulter cette page de temps en temps pour vous assurer que vous êtes satisfait des changements.</p>
        </section>

        <section>
          <h2>Nous contacter</h2>
          <p>Si vous avez des questions concernant cette politique de confidentialité, les pratiques de ce site, ou vos interactions avec ce site, veuillez nous contacter à :</p>
          <p>Abidjan Riviera palmeraie</p>
          <p>+2250574039292</p>
          <p>contact@yatouu.ci</p>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 Yatouu. Tous droits réservés.</p>
      </footer>
    </>

  );
}

export default App;
